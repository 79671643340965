<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="动脉瘤信息" v-show="isShow" @close="Close">
    <div style="display: flex; flex-direction: column">
      <div style="flex: 1; overflow: auto">
        <div class="top-item-row">
          <div class="item-row">
            <span>瘤体位置</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_arterial_approach"
              :value.sync="aneurysmTableInfo.tumorLocation"
            />
          </div>
          <div class="item-row">
            <span>腹主动脉瘤部位分型</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_classification_aneurysm"
              :value.sync="aneurysmTableInfo.siteClassification"
            />
          </div>
          <div class="item-row">
            <span>腹主动脉瘤长度分型</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_classification_aneurysm"
              :value.sync="aneurysmTableInfo.lengthClassification"
            />
          </div>
          <div class="item-row"></div>
          <div class="item-row"></div>
        </div>
        <div class="top-item-row">
          <div class="item-row">
            <span>近端锚定区位置</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_proximal_anchoring_position"
              :value.sync="aneurysmTableInfo.anchoringArea"
            />
          </div>
          <div class="item-row">
            <span>近端锚定区长度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="mm"
              v-model="aneurysmTableInfo.healthyAnchoring"
            />
          </div>
          <div class="item-row">
            <span>锚定区直径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="mm"
              v-model="aneurysmTableInfo.anchoringDiameter"
            />
          </div>
          <div class="item-row">
            <span>锚定区至腹主动脉分叉处长度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="mm"
              v-model="aneurysmTableInfo.abdominalLength"
            />
          </div>
          <div class="item-row"></div>
        </div>
        <div class="top-item-row">
          <div class="item-row">
            <span>主动脉近端瘤颈角度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="°"
              v-model="aneurysmTableInfo.aneurysmNeckAngle"
            />
          </div>
          <div class="item-row">
            <span>瘤颈钙化或血栓程度</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_calcification_or_thrombus"
              :value.sync="aneurysmTableInfo.thrombosisDegree"
            />
          </div>
          <div class="item-row">
            <span>主动脉近端瘤颈形态</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_aortic_aneurysm_neck"
              :value.sync="aneurysmTableInfo.aneurysmMorphology"
            />
          </div>
          <div class="item-row">
            <span>动脉瘤体弯曲指数</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              v-model="aneurysmTableInfo.aneurysmCurvatureIndex"
            />
          </div>
          <div class="item-row">
            <span>动脉瘤体角度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="°"
              v-model="aneurysmTableInfo.aneurysmAngle"
            />
          </div>
        </div>
        <div class="top-item-row">
          <div class="item-row">
            <span>分支血管</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_branching_vessel"
              :value.sync="aneurysmTableInfo.branchVessel"
            />
          </div>
          <div class="item-row">
            <span>瘤体最大径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="mm"
              v-model="aneurysmTableInfo.maximumDiameter"
            />
          </div>
          <div class="item-row">
            <span>髂总动脉受累情况</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_approach"
              :value.sync="aneurysmTableInfo.involvement"
            />
          </div>
          <div class="item-row">
            <span>髂总动脉受累长度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="cm"
              v-model="aneurysmTableInfo.involvementLength"
            />
          </div>
          <div class="item-row">
            <span>左侧髂总动脉受累直径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="cm"
              v-model="aneurysmTableInfo.commonArteryDiameterL"
            />
          </div>
        </div>
        <div class="top-item-row">
          <div class="item-row">
            <span>钙化情况</span>
            <cqt-select
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/AA_calcification_or_thrombus"
              :value.sync="aneurysmTableInfo.calcification"
            />
          </div>
          <div class="item-row">
            <span>髂动脉弯曲指数</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              v-model="aneurysmTableInfo.iliacArteryCurvatureIndex"
            />
          </div>
          <div class="item-row">
            <span>髂动脉角度</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="°"
              v-model="aneurysmTableInfo.iliacArteryAngle"
            />
          </div>
          <div class="item-row"></div>
          <div class="item-row">
            <span>右侧髂总动脉受累直径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="cm"
              v-model="aneurysmTableInfo.commonArteryDiameterR"
            />
          </div>
        </div>
        <div class="top-item-row">
          <div class="item-row">
            <span>是否累及髂内动脉</span>
            <cqt-radio :value.sync="aneurysmTableInfo.involvingInternal" :isReadonly="isReadonly">
              <el-radio label="是" @change="changeInvolvingInternal">是</el-radio>
              <el-radio label="否" @change="changeInvolvingInternal">否</el-radio>
            </cqt-radio>
          </div>
          <div class="item-row">
            <span>左侧髂内动脉受累直径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="cm"
              v-model="aneurysmTableInfo.internalArteryDiameterL"
              :disabled="involvingInternal_status"
            />
          </div>
          <div class="item-row">
            <span>右侧髂内动脉受累直径</span>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              unit="cm"
              v-model="aneurysmTableInfo.internalArteryDiameterR"
              :disabled="involvingInternal_status"
            />
          </div>
          <div class="item-row"></div>
          <div class="item-row"></div>
        </div>
        <div class="top-item-row">
          <el-col v-show="!isReadonly" :span="14" title="处理意见">
            <el-divider content-position="left">处理意见</el-divider>
            <el-radio label="择期造影" v-model="handlingOpinions">择期造影</el-radio>
            <el-radio label="择期支架植入" v-model="handlingOpinions">择期支架植入</el-radio>
            <el-radio label="其他治疗" v-model="handlingOpinions">其他治疗</el-radio>
            <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
          </el-col>
          <el-col v-show="isReadonly" :span="14" title="处理意见">
            <el-divider content-position="left">处理意见</el-divider>
            <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
              {{ handlingOpinions }}
            </el-tag>
          </el-col>
          <el-col v-show="!isReadonly" :span="10">
            <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
              本次治疗
            </el-divider>
            <current-checkbox-select
              v-if="handlingOpinions === '本次治疗'"
              v-model="currentTreatment"
              :currentTreatment="this.currentTreatment"
            ></current-checkbox-select>
          </el-col>
          <el-col v-show="isReadonly" :span="10">
            <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
              本次治疗
            </el-divider>
            <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
              {{ currentTreatment }}
            </el-tag>
          </el-col>
        </div>
      </div>
      <div class="button-panel">
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="Save"
          v-show="!isReadonly"
        >
          保存
        </el-button>
        <el-button
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-close"
          v-debounce="Close"
        >
          关闭
        </el-button>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'AneurysmInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      handlingOpinions: ' ',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      involvingInternal_status: true,
      currentTreatment: null,
      aneurysmTableInfo: {
        tumorLocation: null, //瘤体位置
        siteClassification: null, //腹主动脉瘤部位分型
        lengthClassification: null, //腹主动脉瘤长度分型
        anchoringArea: null, //近端锚定区位置
        healthyAnchoring: null, //健康锚定区长度
        anchoringDiameter: null, //锚定区直径
        abdominalLength: null, //锚定区至腹主动脉分叉处长度
        aneurysmNeckAngle: null, //主动脉近端瘤颈角度
        thrombosisDegree: null, //瘤颈钙化或血栓程度
        aneurysmMorphology: null, //主动脉近端瘤颈形态
        aneurysmCurvatureIndex: null, //动脉瘤体弯曲指数
        aneurysmAngle: null, //动脉瘤体角度
        branchVessel: null, //分支血管
        maximumDiameter: null, //瘤体最大径
        involvement: null, //髂总动脉受累情况
        involvementLength: null, //髂总动脉受累长度
        commonArteryDiameterL: null, //左侧髂总动脉受累直径
        commonArteryDiameterR: null, //右侧髂总动脉受累直径
        calcification: null, //钙化情况
        iliacArteryCurvatureIndex: null, //髂动脉弯曲指数
        iliacArteryAngle: null, //髂动脉角度
        involvingInternal: null, //是否累及髂内动脉
        internalArteryDiameterL: null, //左侧髂内动脉受累直径
        internalArteryDiameterR: null, //右侧髂内动脉受累直径
        handlingOpinions: null, //处理意见
        radiography: null, //造影
        stentImplantation: null //支架植入
      },
      handlingOpinionsInfo: []
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.aneurysmTableInfo.handlingOpinions = val
      }
    },
    currentTreatment: {
      handler(val) {
        this.aneurysmTableInfo.handlingOpinions = '本次治疗'

        if (val && val?.length > 0) {
          this.aneurysmTableInfo.handlingOpinions = ['本次治疗', ...val?.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.aneurysmTableInfo = {}
      if (bloodVessel) this.aneurysmTableInfo = deepClone(bloodVessel)

      let sugs = this.aneurysmTableInfo.handlingOpinions?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs?.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (
        this.aneurysmTableInfo.tumorLocation === null ||
        this.aneurysmTableInfo.tumorLocation === ''
      ) {
        return this.$message.warning('瘤体位置不能为空')
      }
      var idx = this.aneurysmTableInfo.handlingOpinions?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.aneurysmTableInfo.handlingOpinions.substr(0, idx + 1)
        var b = this.aneurysmTableInfo.handlingOpinions.substr(idx + 2)
        this.aneurysmTableInfo.handlingOpinions = a + b
      }

      // this.aneurysmTableInfo.thisTreatment = null
      // if (this.currentTreatment === '') {
      //   this.aneurysmTableInfo.handlingOpinions = null
      // }
      this.$emit('save', this.aneurysmTableInfo)
      this.Close()
      // this.aneurysmTableInfo.handlingOpinions = null
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = null
      // this.aneurysmTableInfo.radiography = null
      // this.aneurysmTableInfo.stentImplantation = null
    },

    changeInvolvingInternal(val) {
      if (this.isReadonly) return

      if (val === '否') {
        this.aneurysmTableInfo.internalArteryDiameterL = ''
        this.aneurysmTableInfo.internalArteryDiameterR = ''
        this.involvingInternal_status = true
      } else {
        this.involvingInternal_status = false
      }
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
