<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 35%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="middle" :zIndex="zIndex" title="髂支支架" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>髂支支架位置</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/AA_position_of_iliac_support"
          v-model="iliacStentInfo.supportPosition"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>支架直径（mm）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="iliacStentInfo.supportDiameter"
          size="mini"
        ></el-input>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>支架长度（mm）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="iliacStentInfo.supportLength"
          size="mini"
        ></el-input>
      </div>
      <div class="item-row">
        <span>掐指支架数量（枚）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="iliacStentInfo.supportNumber"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'AneurysmInfoDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    aorticAneurysmId: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      iliacStentInfo: {
        aorticAneurysmId: null, //主动脉瘤主键
        supportPosition: null, //髂支支架位置
        supportDiameter: null, //支架直径
        supportLength: null, //支架长度
        supportNumber: null //掐指支架数量
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    Show(bloodVessel) {
      this.iliacStentInfo = {}
      if (bloodVessel) this.iliacStentInfo = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      if (this.aorticAneurysmId != '-1' && this.aorticAneurysmId != '') {
        this.iliacStentInfo.aorticAneurysmId = this.aorticAneurysmId

        this.$emit('save', this.iliacStentInfo)
        this.isShow = false
      }
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
