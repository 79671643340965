<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button" v-show="!isReadonly">
        <el-button
          @click="open_save"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>

    <div class="body">
      <div class="valve-basic-information">
        <BasicForm :isReadonly="isReadonly" :basicData="coronaryInfo" ref="BasicForm" />
      </div>
      <div class="aneurysm-info-entry">
        <aneurysm-info-entry
          :allDetail="allDetail"
          :formId="formData.formId"
          v-model="aorticAneurysmId"
          :isReadonly="isReadonly"
          :signed_status="isReadonly"
          ref="AneurysmInfoEntry"
        ></aneurysm-info-entry>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="coronaryInfo"
        complicationUrl="/v1/webconsole/comboData/get/AA_complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/AA_post_diagnosis"
        ref="ComplicationForm"
      />

      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="coronaryInfo.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        />
      </div>

      <!-- 手术结果 -->
      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadonly"
          :templateData="templateResultData"
          :surgeryInfo="coronaryInfo.studyResult"
          :operation="operation"
          ref="SurgeryResult"
        />
      </div> -->

      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="coronaryInfo"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
      <!-- pdf -->
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
        </div>
      </el-dialog>

      <radiography-new-dialog
        ref="RadiographyNewDialog"
        :aorticAneurysmId="aorticAneurysmId"
        @save="radiography_new_dialogSave"
      ></radiography-new-dialog>
    </div>
  </div>
</template>

<script>
import AneurysmInfoEntry from './components/AneurysmInfoEntry.vue'
import RadiographyDialog from './components/RadiographyDialog'
import RadiographyNewDialog from './components/RadiographyNewDialog.vue'
import RenalArteryDialog from './components/RenalArteryDialog'
import LeftCoverDialog from './components/LeftCoverDialog'
import RightReconstructionDialog from './components/RightReconstructionDialog'
import SignReport from '../components/SignReport.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

import BasicForm from './components/BasicForm.vue'
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import ComplicationForm from '../components/ComplicationForm.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'

import { mapState } from 'vuex'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import { differMin } from '../../../utils'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    SignReport,

    AneurysmInfoEntry,
    RadiographyDialog,
    RenalArteryDialog,
    LeftCoverDialog,
    RightReconstructionDialog,

    RadiographyNewDialog,

    BasicForm,
    ComplicationForm,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  data() {
    return {
      loading: true,
      isReadonly: false,
      report_address: '',
      dialogVisible: false,
      reportInfo: null,
      signStatus: false,
      surgicalincision_status: true,
      presetStapler_status: true,
      iliacStent_status: true,
      renalArtery_status: true,
      leftReconstruction_status: true,
      rightReconstruction_status: true,
      // 手术经过结果信息
      operationName: '主动脉瘤',
      surgeryInfo: null,
      operation: 'AorticAneurysm',
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },

      aorticAneurysmId: '-1',
      selectedRow: null,
      tableKey: null,
      aneurysmInfo: {
        arterialApproach: null, //动脉入路
        surgicalincision: null, //是否外科切开
        incisionPosition: '', //切开位置
        indication: null, //适应症
        presetStapler: null, //是否预置缝合器
        leftCommon: null, //左侧股总动脉
        rightCommon: null //右侧股总动脉
      },
      // 造影
      radiographyDetail: {
        anchorLocation: null, //锚定区位置
        anchorLength: null, //锚定区长度
        anchorDiameter: null, //锚定区直径
        lowerLateral: null, //距肾动脉较低侧距离
        bifurcationLength: null, //锚定区至腹主动脉分叉处长度
        supportApproach: null, //主体支架入路
        supportDiameter: null, //主体支架直径
        supportLength: null, //主体支架长度
        radiographyComplication: null, //并发症
        radiographyInternalLeakage: null, //内漏分型
        radiographyTreatmentMethod: null, //处理方式
        iliacStent: null //有无髂支支架
      },
      // 支架植入
      implantationDetail: {
        mainPosition: null, //主体支架近端位置
        renalArtery: null, //是否重建肾动脉
        leftPosition: null, //左髂支远端位置
        leftCover: null, //是否覆盖左髂内动脉
        leftReconstruction: null, //是否重建左髂内动脉
        leftEmbolicCavity: null, //左侧栓塞腔内动脉类型
        rightPosition: null, //右髂支远端位置
        rightCover: null, //是否覆盖右髂内动脉
        rightReconstruction: null, //是否重建右髂内动脉
        rightEmbolicCavity: null, //右侧栓塞腔内动脉类型
        bracketComplication: null, //并发症
        bracketInternalLeakage: null, //内漏分型
        bracketTreatmentMethod: null //处理方式
      },
      radiographyTableDetail: [], // 左右支架列表
      renalArteryTableDetail: [], // 肾动脉
      leftCoverTableDetail: [], // 左
      rightReconstructionDetail: [], // 右

      /* --------------- */
      radiographyLeftTableDetail: [],
      radiographyRightTableDetail: [],
      addRadiographyType: '', // 添加的是左还是右
      /* --------------- */

      coronaryInfo: {}, // 初始化数据
      formList: [],
      // 通用表单
      formData: {
        formId: '',
        id: '',
        items: []
      },
      allDetail: {},
      allInitInfo: {}, // 初始化值
      menuListData: null,
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      itemArr: [
        {
          // 动脉瘤信息录入
          elementId: 'aorticAneurysmInformation',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤支架植入基本信息
          elementId: 'aorticAneurysmImplantation',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤造影基本信息
          elementId: 'aorticAneurysmRadiography',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤髂支支架（表格）
          elementId: 'aorticAneurysmIliacStent',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤左髂内动脉重建支架详情（表格）
          elementId: 'aorticAneurysmLeftIliacBranch',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤右髂内动脉重建支架详情（表格）
          elementId: 'aorticAneurysmRightIliacBranch',
          value: null,
          extendTable: null
        },
        {
          // 主动脉瘤肾动脉重建支架详情（表格）
          elementId: 'aorticAneurysmRenalArtery',
          value: null,
          extendTable: null
        }
      ],
      coronaryDetail: {
        elementId: 'aorticAneurysmInformation',
        value: null,
        extendTable: null
      }
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    }
  },
  mounted() {},
  created() {},
  methods: {
    differMin,
    // 1
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 2 获取表单id
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api
            .get(formUrl, data)
            .then(({ data: res }) => {
              if (res && res.data) {
                if (res.data.length !== 0) {
                  this.formList = res.data
                  this.formData.id = res.data[0].id
                  this.aorticAneurysmId = res.data[0].id
                  this.getTableData()
                } else {
                  // 如果没有数据则使用初始化
                  this.getDefaultInfo()
                }
                this.getReportInfo() // 查询报告状态
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    // 3  查询基本数据（公共） 获取表单数据（私有）
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
          }
          this.coronaryInfo = { ...this.coronaryInfo, ...obj }
          this.allDetail = res.data

          // 扩展数据
          // this._getRadiography() // 造影基本信息
          // this._getImplantation() // 支架信息
          // this._getRadiographyDetails() // 无髂支支架表格
          // this._getRenalArteryDetails() // 肾动脉
          // this._getLeftCoverDetails() // 左髂内动脉
          // this._getRightReconstructionDetails() // 右髂内动脉
        }
      })

      // 查询基本数据（公共）
      this.$api
        .get(
          `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            if (res.data) {
              let obj = {}
              for (let i in res.data) {
                for (let j in this.universalStudy) {
                  if (j === i) {
                    obj[i] = res.data[i]
                  }
                }
              }
              this.coronaryInfo = { ...this.coronaryInfo, ...obj }
            }
          }
        })
    },
    // 初始化数据
    getDefaultInfo() {
      if (this.info) {
        this.$api
          .get(
            `/v1/webconsole/eform/universal/init/${this.info.uuid}/${this.menuListData.moduleId}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              console.log('init', res)
            }
          })
      }
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },

    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },

    open_save() {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('是否保存动脉基本信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = [] // 重置item
      let aorticInfo = this.$refs.BasicForm.getAllBasicData() // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      this.loading = true
      let differentFields = {}

      // 按要求赋值
      for (let item in aorticInfo) {
        if (this.universalStudy?.hasOwnProperty(item)) {
          this.universalStudy[item] = aorticInfo[item]
        }
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = aorticInfo[item]
        }
      }

      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }

      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord, // 手术经过
        // studyResult: this.$refs.SurgeryResult.studyResult // 手术结果
      } // universalStudy字段
      // this.handleData()
      this.handleNewData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.aorticAneurysmId = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleNewData() {
      if (this.$refs.AneurysmInfoEntry.aneurysmTableData.length !== 0) {
        const Arr = this.$refs.AneurysmInfoEntry.aneurysmTableData.map((element) => {
          element.aorticAneurysmIliacStent = [
            ...(element?.radiographyLeftTableDetail || []),
            ...(element?.radiographyRightTableDetail || [])
          ] // 合并
          const { radiographyLeftTableDetail, radiographyRightTableDetail, ...otherProperties } =
            element || [undefined]
          return this.convertNewElement(otherProperties)
        })
        this.coronaryDetail.extendTable = Arr
        this.formData.items.push(this.coronaryDetail)
      }
    },
    convertNewElement(element) {
      delete element.id
      const arr = Object.entries(element).map(([i, value]) => {
        if (value && typeof value !== 'string') {
          let subDetail
          if (
            i === 'aorticAneurysmIliacStent' ||
            i === 'aorticAneurysmLeftIliacBranch' ||
            i === 'aorticAneurysmRightIliacBranch'
          ) {
            subDetail = element[i]?.map((ele) => {
              return Object.entries(ele).map(([k, v]) => ({
                elementId: k,
                value: v,
                extendTable: null
              }))
            })
          } else {
            subDetail = [
              Object.entries(value).map(([j, ele]) => ({
                elementId: j,
                value: ele,
                extendTable: null
              }))
            ]
          }
          return {
            elementId: i,
            value: null,
            extendTable: subDetail
          }
        } else {
          return {
            elementId: i,
            value: value,
            extendTable: null
          }
        }
      })
      return arr
    },
    handleData() {
      let AneurysmInfoEntryArr = [] //动脉瘤信息录入
      let RadiographyDetailArr = [] //造影
      let ImplantationDetailArr = [] //支架植入
      let RadiographyTableDetailArr = [] //主动脉瘤髂支支架（表格）
      let RenalArteryTableDetailArr = [] //肾动脉表格（表格）
      let LeftCoverTableDetail = [] //左髂内动脉（表格）
      let RightReconstructionDetail = [] //右髂内动脉（表格）

      // 动脉瘤信息录入
      if (this.$refs.AneurysmInfoEntry.aneurysmTableData.length !== 0) {
        AneurysmInfoEntryArr = this.$refs.AneurysmInfoEntry.aneurysmTableData.map((element) =>
          this.convertElement(element)
        )
      }
      // 造影
      RadiographyDetailArr = Array.from(Array(1)).map((_, index) => {
        return this.convertElement(this.radiographyDetail)
      })
      // 支架植入
      ImplantationDetailArr = Array.from(Array(1)).map((_, index) => {
        return this.convertElement(this.implantationDetail)
      })
      //主动脉瘤髂支支架（表格）
      if (this.implantationDetail.iliacStent !== '否' && this.radiographyTableDetail.length !== 0) {
        RadiographyTableDetailArr = this.radiographyTableDetail.map((element) =>
          this.convertElement(element)
        )
      }
      //肾动脉表格（表格）
      if (
        this.implantationDetail.renalArtery !== '否' &&
        this.renalArteryTableDetail.length !== 0
      ) {
        RenalArteryTableDetailArr = this.renalArteryTableDetail.map((element) =>
          this.convertElement(element)
        )
      }
      //左髂内动脉（表格）
      if (
        this.implantationDetail.leftReconstruction !== '否' &&
        this.leftCoverTableDetail.length !== 0
      ) {
        LeftCoverTableDetail = this.leftCoverTableDetail.map((element) =>
          this.convertElement(element)
        )
      }
      //右髂内动脉（表格）
      if (
        this.implantationDetail.rightReconstruction !== '否' &&
        this.rightReconstructionDetail.length !== 0
      ) {
        RightReconstructionDetail = this.rightReconstructionDetail.map((element) =>
          this.convertElement(element)
        )
      }

      this.itemArr.forEach((item) => {
        if (item.elementId === 'aorticAneurysmInformation') {
          //动脉瘤信息录入
          item.extendTable = AneurysmInfoEntryArr
        }
        if (item.elementId === 'aorticAneurysmImplantation') {
          // 主动脉瘤支架植入基本信息
          item.extendTable = ImplantationDetailArr
        }
        if (item.elementId === 'aorticAneurysmRadiography') {
          // 主动脉瘤造影基本信息
          item.extendTable = RadiographyDetailArr
        }
        if (item.elementId === 'aorticAneurysmIliacStent') {
          // 主动脉瘤髂支支架（表格）
          item.extendTable = RadiographyTableDetailArr
        }
        if (item.elementId === 'aorticAneurysmLeftIliacBranch') {
          // 主动脉瘤左髂内动脉重建支架详情（表格）
          item.extendTable = LeftCoverTableDetail
        }
        if (item.elementId === 'aorticAneurysmRightIliacBranch') {
          // 主动脉瘤左髂内动脉重建支架详情（表格）
          item.extendTable = RightReconstructionDetail
        }
        if (item.elementId === 'aorticAneurysmRenalArtery') {
          // 主动脉瘤肾动脉重建支架详情（表格）
          item.extendTable = RenalArteryTableDetailArr
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      delete element.ids
      const arr = Object.entries(element).map(([i, value]) => {
        if (value && typeof value !== 'string') {
          let subDetail
          subDetail = [
            Object.entries(value).map(([j, ele]) => ({
              elementId: j,
              value: ele,
              extendTable: null
            }))
          ]
          return {
            elementId: i,
            value: null,
            extendTable: subDetail
          }
        } else {
          return {
            elementId: i,
            value: value,
            extendTable: null
          }
        }
      })
      return arr
    },

    _rowClick(row) {
      if (this.isReadonly) return
      this.selectedRow = row
    },

    // 造影基本信息
    async _getRadiography() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmRadiography.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmRadiography')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            for (let item in res.data[0]) {
              if (this.radiographyDetail.hasOwnProperty(item)) {
                this.radiographyDetail[item] = res.data[0][item]
              }
            }
            if (this.radiographyDetail.iliacStent === '有') {
              this.iliacStent_status = false
            } else {
              this.iliacStent_status = true
            }
          } else {
            this._clearRadiography()
          }
        },
        () => {}
      )
    },
    _clearRadiography() {
      if (this.isReadonly) return
      this.radiographyDetail = {
        id: null, //不带id为新增，带id为修改
        anchorLocation: null, //锚定区位置
        anchorLength: null, //锚定区长度
        anchorDiameter: null, //锚定区直径
        lowerLateral: null, //距肾动脉较低侧距离
        bifurcationLength: null, //锚定区至腹主动脉分叉处长度
        supportApproach: null, //主体支架入路
        supportDiameter: null, //主体支架直径
        supportLength: null, //主体支架长度
        radiographyComplication: null, //并发症
        radiographyInternalLeakage: null, //内漏分型
        radiographyTreatmentMethod: null, //处理方式
        iliacStent: null //有无髂支支架
      }
    },
    radiographyInsert() {
      if (this.isReadonly) return
      this.$refs.radiographyDialog.Show()
    },
    //  主动脉瘤髂支支架（表格）删除
    radiographyDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyTableDetail.splice(index, 1)
          }
        }
      }
    },
    //  主动脉瘤髂支支架（表格）保存
    radiography_dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.radiographyTableDetail.length; i++) {
          const element = this.radiographyTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.radiographyTableDetail[i] = val
            this.$set(this.radiographyTableDetail, i, this.radiographyTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.radiographyTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.radiographyTableDetail.length; i++) {
          const element = this.radiographyTableDetail[i]
          if (val.id === element.id) {
            this.radiographyTableDetail[i] = val
            this.$set(this.radiographyTableDetail, i, this.radiographyTableDetail[i])
            break
          }
        }
      }
    },
    // 主动脉瘤髂支支架（表格）
    async _getRadiographyDetails() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmIliacStent.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmIliacStent')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.radiographyTableDetail = res.data
        } else {
          this.radiographyTableDetail = []
        }
      })
    },

    // 主动脉瘤支架植入基本信息
    async _getImplantation() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmImplantation.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmImplantation')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res.data && res.data?.length > 0) {
          for (let item in res.data[0]) {
            if (this.implantationDetail.hasOwnProperty(item)) {
              this.implantationDetail[item] = res.data[0][item]
            }
          }
          if (this.implantationDetail) {
            if (this.implantationDetail.renalArtery === '是') {
              this.renalArtery_status = false
            }
            if (this.implantationDetail.leftReconstruction === '是') {
              this.leftReconstruction_status = false
            }
            if (this.implantationDetail.rightReconstruction === '是') {
              this.rightReconstruction_status = false
            }
          }
        } else {
          this._clearImplantation()
        }
      })
    },
    _clearImplantation() {
      if (this.isReadonly) return
      this.implantationDetail = {
        id: null, //不带id为新增，带id为修改
        mainPosition: null, //主体支架近端位置
        renalArtery: null, //是否重建肾动脉
        leftPosition: null, //左髂支远端位置
        leftCover: null, //是否覆盖左髂内动脉
        leftReconstruction: null, //是否重建左髂内动脉
        leftEmbolicCavity: null, //左侧栓塞腔内动脉类型
        rightPosition: null, //右髂支远端位置
        rightCover: null, //是否覆盖右髂内动脉
        rightReconstruction: null, //是否重建右髂内动脉
        rightEmbolicCavity: null, //右侧栓塞腔内动脉类型
        bracketComplication: null, //并发症
        bracketInternalLeakage: null, //内漏分型
        bracketTreatmentMethod: null //处理方式
      }
    },
    renalArteryInsert() {
      if (this.isReadonly) return

      this.$refs.renalArteryDialog.Show()
    },
    // 肾动脉表格删除
    renalArteryDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.renalArteryTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.renalArteryTableDetail.splice(index, 1)
          }
        } else {
          const index = this.renalArteryTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.renalArteryTableDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = {}
      //刷新表格
      this.tableKey = Math.random()
    },
    // 肾动脉 保存
    renalArtery_dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.renalArteryTableDetail.length; i++) {
          const element = this.renalArteryTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.renalArteryTableDetail[i] = val
            this.$set(this.renalArteryTableDetail, i, this.renalArteryTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.renalArteryTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.renalArteryTableDetail.length; i++) {
          const element = this.renalArteryTableDetail[i]
          if (val.id === element.id) {
            this.renalArteryTableDetail[i] = val
            this.$set(this.renalArteryTableDetail, i, this.renalArteryTableDetail[i])
            break
          }
        }
      }
    },
    // 肾动脉
    async _getRenalArteryDetails() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmRenalArtery.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmRenalArtery')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res.data && res.data?.length > 0) {
          this.renalArteryTableDetail = res.data
        } else {
          this.renalArteryTableDetail = []
        }
      })
    },
    leftCoverInsert() {
      if (this.isReadonly) return

      this.$refs.leftCoverDialog.Show()
    },
    // 左髂内动脉 删除
    leftCoverDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.leftCoverTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.leftCoverTableDetail.splice(index, 1)
          }
        } else {
          const index = this.leftCoverTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.leftCoverTableDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = null
      //刷新表格
      this.tableKey = Math.random()
    },
    // 左髂内动脉 保存
    leftCover_dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.leftCoverTableDetail.length; i++) {
          const element = this.leftCoverTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.leftCoverTableDetail[i] = val
            this.$set(this.leftCoverTableDetail, i, this.leftCoverTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.leftCoverTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.leftCoverTableDetail.length; i++) {
          const element = this.leftCoverTableDetail[i]
          if (val.id === element.id) {
            this.leftCoverTableDetail[i] = val
            this.$set(this.leftCoverTableDetail, i, this.leftCoverTableDetail[i])
            break
          }
        }
      }
    },
    // 左髂内动脉
    async _getLeftCoverDetails() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmLeftIliacBranch.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmLeftIliacBranch')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res.data && res.data?.length > 0) {
          this.leftCoverTableDetail = res.data
        } else {
          this.leftCoverTableDetail = []
        }
      })
    },
    rightReconstructionInsert() {
      if (this.isReadonly) return

      this.$refs.rightReconstructionDialog.Show()
    },
    // 右髂内动脉 删除
    rightReconstructionDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.rightReconstructionDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.rightReconstructionDetail.splice(index, 1)
          }
        } else {
          const index = this.rightReconstructionDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.rightReconstructionDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = null
      //刷新表格
      this.tableKey = Math.random()
    },
    // 右髂内动脉 保存
    rightReconstruction_dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.rightReconstructionDetail.length; i++) {
          const element = this.rightReconstructionDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.rightReconstructionDetail[i] = val
            this.$set(this.rightReconstructionDetail, i, this.rightReconstructionDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.rightReconstructionDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.rightReconstructionDetail.length; i++) {
          const element = this.rightReconstructionDetail[i]
          if (val.id === element.id) {
            this.rightReconstructionDetail[i] = val
            this.$set(this.rightReconstructionDetail, i, this.rightReconstructionDetail[i])
            break
          }
        }
      }
    },
    // 右髂内动脉 获取
    async _getRightReconstructionDetails() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticAneurysmRightIliacBranch.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmRightIliacBranch')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res.data && res.data?.length > 0) {
          this.rightReconstructionDetail = res.data
        } else {
          this.rightReconstructionDetail = []
        }
      })
    },
    changeSurgicalincision(val) {
      if (this.isReadonly) return

      if (val === '否') {
        this.aneurysmInfo.incisionPosition = ''
        this.surgicalincision_status = true
      } else {
        this.surgicalincision_status = false
      }
    },
    changePresetStapler(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.aneurysmInfo.leftCommon = ''
        this.aneurysmInfo.rightCommon = ''
        this.presetStapler_status = true
      } else {
        this.presetStapler_status = false
      }
    },
    changeIliacStent(val) {
      if (this.isReadonly) return
      if (val === '有') {
        this.iliacStent_status = false
      } else {
        this.iliacStent_status = true
      }
    },
    changeRenalArtery(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.renalArtery_status = false
      } else {
        this.renalArtery_status = true
      }
    },
    changeLeftReconstruction(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.leftReconstruction_status = false
      } else {
        this.leftReconstruction_status = true
      }
    },
    changeRightReconstruction(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.rightReconstruction_status = false
      } else {
        this.rightReconstruction_status = true
      }
    },
    /*------------------------------------------------------------*/
    // 左（增）
    addLeftRadiography() {
      if (this.isReadonly) return
      this.addRadiographyType = 'left'
      this.$refs.RadiographyNewDialog.Show()
    },
    // 左（删）
    radiographyLeftDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyLeftTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyLeftTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyLeftTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyLeftTableDetail.splice(index, 1)
          }
        }
      }
    },
    // 右（增）
    addRightRadiography() {
      if (this.isReadonly) return
      this.addRadiographyType = 'right'
      this.$refs.RadiographyNewDialog.Show()
    },
    // 右（删）
    radiographyRightDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyRightTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyRightTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyRightTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyRightTableDetail.splice(index, 1)
          }
        }
      }
    },
    radiography_new_dialogSave(val) {
      this.selectedInfo = null
      if (this.addRadiographyType == 'left') {
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.radiographyLeftTableDetail.length; i++) {
            const element = this.radiographyLeftTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.radiographyLeftTableDetail[i] = val
              this.$set(this.radiographyLeftTableDetail, i, this.radiographyLeftTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.radiographyLeftTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.radiographyLeftTableDetail.length; i++) {
            const element = this.radiographyLeftTableDetail[i]
            if (val.id === element.id) {
              this.radiographyLeftTableDetail[i] = val
              this.$set(this.radiographyLeftTableDetail, i, this.radiographyLeftTableDetail[i])
              break
            }
          }
        }
      } else if (this.addRadiographyType == 'right') {
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.radiographyRightTableDetail.length; i++) {
            const element = this.radiographyRightTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.radiographyRightTableDetail[i] = val
              this.$set(this.radiographyRightTableDetail, i, this.radiographyRightTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.radiographyRightTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.radiographyRightTableDetail.length; i++) {
            const element = this.radiographyRightTableDetail[i]
            if (val.id === element.id) {
              this.radiographyRightTableDetail[i] = val
              this.$set(this.radiographyRightTableDetail, i, this.radiographyRightTableDetail[i])
              break
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.implantation-table-tab {
  border-style: solid;
  border-width: medium;
  border-color: #ebeef5;
  display: flex;
  width: 99%;
  .el-table {
    border: 1px solid lightblue;
    border-left-style: none;
  }
  .implantation-table-detail {
    display: flex;
    .implantation-table-detail-item {
      display: flex;
      .input-form-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }
    .vertical-text {
      border: 1px solid lightblue;
      border-top-style: none;
      border-bottom-style: none;
      padding: 5px;
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: bt-rl;
      writing-mode: vertical-rl;
      text-align: center;
    }
  }
  .header {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    line-height: 30px;
    .header-button {
      width: 100px;
    }
  }
}
.radiography-table-tab {
  border-style: solid;
  border-width: medium;
  border-color: #ebeef5;
  display: flex;
  width: 99%;
  padding: 10px 0;
  .radiography-table-left {
    margin: 10px;
    width: 100%;
  }
  .radiography-table-right {
    margin: 10px;
    width: 40%;
    .input-form-left {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
    }
    .input-form-right {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
    }
  }
  .header {
    display: flex;
    .title-left {
      width: 40%;
      text-align: center;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-right {
      width: 56%;
      text-align: center;
      min-height: 30px;
      line-height: 30px;
      padding-bottom: 5px;
      .header-button {
        width: 100px;
      }
    }
  }
}
.icon-finished::before {
  content: url('../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../assets/img/warn.png');
}
.equipment_style {
  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .valve-basic-information {
      // border-style: solid;
      // border-width: medium;
      // border-color: #f2f6fc;
      // .valve-basic-information-head {
      //   height: 50px;
      //   display: flex;
      //   width: 100%;
      //   align-items: center;
      //   .except-button {
      //     height: 3rem;
      //     line-height: 3rem;
      //     display: flex;
      //     .valve-basic-information-head-text {
      //       padding-left: 10px;
      //       padding-right: 10px;
      //       font-size: 14px;
      //     }
      //   }
      //   .save-button {
      //     margin-right: 0.5rem;
      //     padding-left: 10px;
      //   }
      // }
      .valve-basic-information-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-form-left {
          width: 70%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-form-right {
          width: 30%;
        }
        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
          .input_has_unit {
            margin: 0;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }

    .pre-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
      margin-top: 30px;
      margin-bottom: 30px;

      .pre-expansions-head {
        button {
          margin-right: 0.5rem;
        }
        display: flex;
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;

          // bug

          padding-bottom: 5px;
        }
      }
    }

    .post-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #e4e7ed;
      margin-top: 30px;
      margin-bottom: 30px;

      .post-expansions-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
    .valve-implants-number {
      border-style: solid;
      border-width: medium;
      border-color: #dcdfe6;
      margin-top: 30px;
      margin-bottom: 30px;

      .valve-implants-number-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }

    .aneurysm-info-entry {
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 40%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 45%;
          margin: 0.4rem 1rem;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
      .info-table {
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 20px 15px;
        border-radius: 5px;
        position: relative;
        background-color: #fff !important;
        box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
        /deep/.el-table .cell {
          white-space: normal;
        }
        /deep/.top-item-row .item-row span {
          margin: 0 0 0 5px;
        }
        button {
          margin-right: 0.5rem;
        }
      }
      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
      }
    }
  }
}
</style>
