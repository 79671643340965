<template>
  <div>
    <div class="info-table">
      <div style="display: flex">
        <span class="inner-title">动脉瘤信息{{ isReadonly ? '' : '录入' }}</span>
      </div>
      <div style="margin: 10px 0 0 10px">
        <div v-show="!isReadonly">
          <el-button
            size="mini"
            type="primary"
            class="commonBtn"
            @click="aneurysmInsert"
            :disabled="signed_status"
          >
            新增
          </el-button>
          <el-button
            size="mini"
            type="warning"
            class="commonBtn"
            @click="aneurysmModify"
            :disabled="signed_status"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="danger"
            class="commonBtn"
            @click="aneurysmDelete"
            :disabled="signed_status"
          >
            删除
          </el-button>
        </div>

        <el-table
          class="inner-table"
          highlight-current-row
          :data="aneurysmTableData"
          :header-cell-style="{ padding: 0 }"
          height="200px"
          style="width: 99%"
          @row-click="_rowClick"
          @row-dblclick="_doubleModify"
          border
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="tumorLocation" label="瘤体位置"></el-table-column>
          <el-table-column prop="siteClassification" label="腹主动脉瘤部位分型"></el-table-column>
          <el-table-column prop="lengthClassification" label="腹主动脉瘤长度分型"></el-table-column>
          <el-table-column prop="anchoringArea" label="近端锚定位置"></el-table-column>
          <el-table-column prop="healthyAnchoring" label="健康锚定长度"></el-table-column>
          <el-table-column prop="anchoringDiameter" label="锚定区直径"></el-table-column>
          <el-table-column
            prop="abdominalLength"
            label="锚定区至主动脉分叉处长度"
          ></el-table-column>
          <el-table-column prop="aneurysmNeckAngle" label="主动脉近端瘤颈角度"></el-table-column>
          <el-table-column prop="thrombosisDegree" label="瘤颈钙化或血栓程度"></el-table-column>
          <el-table-column prop="aneurysmMorphology" label="主动脉近端瘤颈形态"></el-table-column>
          <el-table-column prop="aneurysmCurvatureIndex" label="动脉瘤体弯曲指数"></el-table-column>
          <el-table-column prop="aneurysmAngle" label="动脉瘤体角度"></el-table-column>
          <el-table-column prop="branchVessel" label="分支血管"></el-table-column>
          <el-table-column prop="maximumDiameter" label="瘤体最大径"></el-table-column>
          <el-table-column prop="involvement" label="髂总动脉受累情况"></el-table-column>
          <el-table-column prop="involvementLength" label="髂总动脉受累长度"></el-table-column>
          <el-table-column
            prop="commonArteryDiameterL"
            label="左侧髂总动脉受累直径"
          ></el-table-column>
          <el-table-column
            prop="commonArteryDiameterR"
            label="右侧髂总动脉受累直径"
          ></el-table-column>
          <el-table-column prop="calcification" label="钙化情况"></el-table-column>
          <el-table-column
            prop="iliacArteryCurvatureIndex"
            label="髂动脉弯曲指数"
          ></el-table-column>
          <el-table-column prop="iliacArteryAngle" label="髂动脉角度"></el-table-column>
          <el-table-column prop="involvingInternal" label="是否累及髂内动脉"></el-table-column>
          <el-table-column
            prop="internalArteryDiameterL"
            label="左侧髂内总动脉受累直径"
          ></el-table-column>
          <el-table-column
            prop="internalArteryDiameterR"
            label="右侧髂内总动脉受累直径"
          ></el-table-column>
          <el-table-column prop="handlingOpinions" label="本次处理意见"></el-table-column>
          <!-- <el-table-column prop="thisTreatment" label="本次治疗"></el-table-column> -->
        </el-table>
        <aneurysm-info-dialog
          ref="aneurysmInfoDialog"
          :isReadonly="isReadonly"
          @save="_dialogSave"
        ></aneurysm-info-dialog>
      </div>
    </div>

    <!-- 造影 -->
    <div
      v-if="
        selectedInfo &&
        selectedInfo.handlingOpinions &&
        selectedInfo.handlingOpinions?.includes('造影')
      "
      class="aneurysm-info-entry"
    >
      <div class="info-table">
        <div style="display: flex">
          <span class="inner-title">造影</span>
        </div>
        <div class="equipment_style" style="margin: 10px 0 0 10px">
          <div class="surgery-information">
            <el-tabs type="border-card">
              <el-tab-pane label="处理结果">
                <div class="radiography-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">并发症</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AA_complication"
                        :value.sync="radiographyDetail.radiographyComplication"
                        @update:value="changeComplication"
                      />
                    </div>
                    <div v-if="showNLType" class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">内漏分型</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_internal_leakage_type"
                        :value.sync="radiographyDetail.radiographyInternalLeakage"
                      />
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="锚定区">
                <div class="radiography-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">锚定区位置</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AA_proximal_anchoring_position"
                        :value.sync="radiographyDetail.anchorLocation"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">锚定区长度</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.anchorLength"
                        />
                      </div>
                    </div>

                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">锚定区直径</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.anchorDiameter"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">
                        距肾动脉较低侧距离
                      </div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.lowerLateral"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">
                        锚定区至腹主动脉分叉处长度
                      </div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.bifurcationLength"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="主体支架">
                <div class="radiography-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架入路</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AA_arterial_approach"
                        :value.sync="radiographyDetail.supportApproach"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架直径</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.supportDiameter"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架长度</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.supportLength"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="左髂支">
                <div class="radiography-table-right">
                  <div class="header">
                    <div style="margin: 10px 0" v-show="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="addLeftRadiography"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="radiographyLeftDelete"
                      >
                        删除
                      </el-button>
                    </div>
                  </div>
                  <el-table
                    :data="radiographyLeftTableDetail"
                    class="inner-table"
                    highlight-current-row
                    :header-cell-style="{ padding: 0 }"
                    :key="tableKey"
                    @row-click="_rowClickOther"
                    height="200px"
                    style="width: 100%"
                    border
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column prop="supportPosition" label="髂支支架位置"></el-table-column>
                    <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                    <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                    <el-table-column prop="supportNumber" label="掐指支架位置"></el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="右髂支">
                <div class="radiography-table-right">
                  <div class="header">
                    <div style="margin: 10px 0" v-show="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="addRightRadiography"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="radiographyRightDelete"
                      >
                        删除
                      </el-button>
                    </div>
                  </div>
                  <el-table
                    :data="radiographyRightTableDetail"
                    class="inner-table"
                    highlight-current-row
                    :header-cell-style="{ padding: 0 }"
                    :key="tableKey"
                    @row-click="_rowClickOther"
                    height="200px"
                    style="width: 100%"
                    border
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column prop="supportPosition" label="髂支支架位置"></el-table-column>
                    <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                    <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                    <el-table-column prop="supportNumber" label="掐指支架位置"></el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 支架植入 -->
    <div
      v-if="
        selectedInfo &&
        selectedInfo.handlingOpinions &&
        selectedInfo.handlingOpinions?.includes('支架植入')
      "
      class="aneurysm-info-entry"
    >
      <div class="info-table">
        <div style="display: flex">
          <span class="inner-title">支架植入</span>
        </div>
        <div class="equipment_style" style="margin: 10px 0 0 10px">
          <div class="surgery-information">
            <el-tabs type="border-card">
              <el-tab-pane label="肾动脉">
                <div class="implantation-table-detail-item">
                  <div
                    class="input-form"
                    style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        主体支架近端位置
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_proximal_position2"
                        :value.sync="implantationDetail.mainPosition"
                      />
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        是否重建肾动脉
                      </div>
                      <cqt-radio
                        :value.sync="implantationDetail.renalArtery"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是" @change="changeRenalArtery">是</el-radio>
                        <el-radio label="否" @change="changeRenalArtery">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                      v-if="renalArtery_status"
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        重建位置
                      </div>
                      <el-radio-group v-model="renalArteryInfo.reconstructionLocation">
                        <el-radio label="左肾动脉">左肾动脉</el-radio>
                        <el-radio label="右肾动脉">右肾动脉</el-radio>
                      </el-radio-group>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                      v-if="renalArtery_status"
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        支架直径（mm）
                      </div>
                      <cqt-input
                        :isReadonly="isReadonly"
                        style="width: 193px; margin-left: 15px"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="renalArteryInfo.supportDiameter"
                        size="mini"
                      ></cqt-input>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                      v-if="renalArtery_status"
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        支架长度（mm）
                      </div>
                      <cqt-input
                        :isReadonly="isReadonly"
                        style="width: 194px; margin-left: 15px"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="renalArteryInfo.supportLength"
                        size="mini"
                      ></cqt-input>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                      v-if="renalArtery_status"
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        支架数量（枚）
                      </div>
                      <cqt-input
                        :isReadonly="isReadonly"
                        style="width: 180px"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="renalArteryInfo.supportNumber"
                        size="mini"
                      ></cqt-input>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="左髂内动脉">
                <div class="implantation-table-detail-item">
                  <div
                    class="input-form"
                    style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        左髂支远端位置
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_position_of_iliac_branch"
                        :value.sync="implantationDetail.leftPosition"
                      />
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        是否覆盖左髂内动脉
                      </div>
                      <cqt-radio
                        :value.sync="implantationDetail.leftCover"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        是否重建左骼内动脉
                      </div>
                      <cqt-radio
                        :value.sync="implantationDetail.leftReconstruction"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是" @change="changeLeftReconstruction">是</el-radio>
                        <el-radio label="否" @change="changeLeftReconstruction">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        左侧栓塞腔内动脉类型
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_type_of_endovascular_artery"
                        :value.sync="implantationDetail.leftEmbolicCavity"
                      />
                    </div>
                  </div>
                  <div style="padding: 5px 5px 5px 0px">
                    <div class="header" v-show="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="leftCoverInsert"
                        :disabled="leftReconstruction_status"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="leftCoverDelete"
                        :disabled="leftReconstruction_status"
                      >
                        删除
                      </el-button>
                    </div>
                    <el-table
                      :data="leftCoverTableDetail"
                      class="inner-table"
                      highlight-current-row
                      :header-cell-style="{ padding: 0 }"
                      :key="tableKey"
                      @row-click="_rowClickOther"
                      height="220px"
                      style="width: 100%"
                      border
                      :cell-style="{ 'text-align': 'center' }"
                    >
                      <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                      <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                      <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
                    </el-table>
                    <left-cover-dialog
                      ref="leftCoverDialog"
                      @save="leftCover_dialogSave"
                    ></left-cover-dialog>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="右髂内动脉">
                <div class="implantation-table-detail-item">
                  <div
                    class="input-form"
                    style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        右髂支远端位置
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_position_of_iliac_branch"
                        :value.sync="implantationDetail.rightPosition"
                      />
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        是否覆盖右髂内动脉
                      </div>
                      <cqt-radio
                        :value.sync="implantationDetail.rightCover"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        是否重建右骼内动脉
                      </div>
                      <cqt-radio
                        :value.sync="implantationDetail.rightReconstruction"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是" @change="changeRightReconstruction">是</el-radio>
                        <el-radio label="否" @change="changeRightReconstruction">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div
                      class="input-form-item"
                      style="
                        min-width: 30%;
                        margin: 0.4rem 1.5rem;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <div
                        class="input-form-item-label"
                        style="width: 140px; padding: 10px; text-align: center"
                      >
                        右侧栓塞腔内动脉类型
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_type_of_endovascular_artery"
                        :value.sync="implantationDetail.rightEmbolicCavity"
                      />
                    </div>
                  </div>
                  <div style="padding: 5px 5px 5px 0px">
                    <div class="header" v-show="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="rightReconstructionInsert"
                        :disabled="rightReconstruction_status"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="rightReconstructionDelete"
                        :disabled="rightReconstruction_status"
                      >
                        删除
                      </el-button>
                    </div>
                    <el-table
                      :data="rightReconstructionDetail"
                      class="inner-table"
                      highlight-current-row
                      :header-cell-style="{ padding: 0 }"
                      :key="tableKey"
                      @row-click="_rowClickOther"
                      height="220px"
                      style="width: 100%"
                      border
                      :cell-style="{ 'text-align': 'center' }"
                    >
                      <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                      <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                      <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
                    </el-table>
                    <right-reconstruction-dialog
                      ref="rightReconstructionDialog"
                      @save="rightReconstruction_dialogSave"
                    ></right-reconstruction-dialog>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="implantation-table-detail"></div>
          </div>
        </div>
      </div>
    </div>
    <radiography-new-dialog
      ref="RadiographyNewDialog"
      :aorticAneurysmId="aorticAneurysmId"
      @save="radiography_new_dialogSave"
    ></radiography-new-dialog>
  </div>
</template>

<script>
import AneurysmInfoDialog from './AneurysmInfoDialog.vue'

import RadiographyNewDialog from './RadiographyNewDialog.vue'
// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

import RenalArteryDialog from './RenalArteryDialog'
import LeftCoverDialog from './LeftCoverDialog'
import RightReconstructionDialog from './RightReconstructionDialog'

export default {
  name: 'AneurysmInfoEntry',
  components: {
    AneurysmInfoDialog,

    RadiographyNewDialog,

    CqtSelect,
    CqtInput,
    CqtRadio,

    RenalArteryDialog,
    LeftCoverDialog,
    RightReconstructionDialog
  },
  props: {
    aorticAneurysmId: {
      type: String
    },
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    },
    selectedInfo: {
      handler(val) {
        if (val) {
          this.getAllSuggestions(val)
        }
      }
    },
    'implantationDetail.renalArtery': {
      handler(val) {
        if (val && val === '是') {
          this.renalArtery_status = true
        } else {
          this.renalArtery_status = false
        }
      }
    },
    'implantationDetail.leftReconstruction': {
      handler(val) {
        if (val && val === '是') {
          this.leftReconstruction_status = false
        } else {
          this.leftReconstruction_status = true
        }
      }
    },
    'implantationDetail.rightReconstruction': {
      handler(val) {
        if (val && val === '是') {
          this.rightReconstruction_status = false
        } else {
          this.rightReconstruction_status = true
        }
      }
    }
  },
  data() {
    return {
      aneurysmTableData: [],
      selectedInfo: null,
      tableKey: null,
      // 造影
      radiographyDetail: {
        anchorLocation: null, //锚定区位置
        anchorLength: null, //锚定区长度
        anchorDiameter: null, //锚定区直径
        lowerLateral: null, //距肾动脉较低侧距离
        bifurcationLength: null, //锚定区至腹主动脉分叉处长度
        supportApproach: null, //主体支架入路
        supportDiameter: null, //主体支架直径
        supportLength: null, //主体支架长度
        radiographyComplication: null, //并发症
        radiographyInternalLeakage: null, //内漏分型
        radiographyTreatmentMethod: null, //处理方式
        iliacStent: null //有无髂支支架
      },
      // 支架植入
      implantationDetail: {
        mainPosition: null, //主体支架近端位置
        renalArtery: null, //是否重建肾动脉
        leftPosition: null, //左髂支远端位置
        leftCover: null, //是否覆盖左髂内动脉
        leftReconstruction: null, //是否重建左髂内动脉
        leftEmbolicCavity: null, //左侧栓塞腔内动脉类型
        rightPosition: null, //右髂支远端位置
        rightCover: null, //是否覆盖右髂内动脉
        rightReconstruction: null, //是否重建右髂内动脉
        rightEmbolicCavity: null, //右侧栓塞腔内动脉类型
        bracketComplication: null, //并发症
        bracketInternalLeakage: null, //内漏分型
        bracketTreatmentMethod: null //处理方式
      },

      aorticAneurysmImplantation: [], //支架基本信息
      renalArteryTableDetail: [], // 肾动脉
      leftCoverTableDetail: [], // 左
      rightReconstructionDetail: [], // 右
      radiographyTableDetail: [], // 左右支架列表

      /* --------------- */
      renalArteryInfo: {
        reconstructionLocation: null, //重建位置
        supportDiameter: null, //支架直径
        supportLength: null, //支架长度
        supportNumber: null //支架数量
      }, // 肾动脉一条信息
      radiographyLeftTableDetail: [],
      radiographyRightTableDetail: [],
      addRadiographyType: '', // 添加的是左还是右
      renalArtery_status: false, // 是否重建肾动脉
      leftReconstruction_status: true,
      rightReconstruction_status: true,
      selectedRow: null, // 其他选中信息
      showNLType: false
      /* --------------- */
    }
  },
  created() {},
  methods: {
    async _initInfoValue() {
      if (this.nerveAneurysmId == '-1') return
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.aorticAneurysmInformation.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticAneurysmInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            console.log('aneurysmTableData', res.data)
            this.aneurysmTableData = res.data?.map((item) => ({ ...item, detailIndex: item.id }))
            for (let i = 0; i < this.aneurysmTableData.length; i++) {
              const element = this.aneurysmTableData[i]
              if (element.handlingOpinions) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    aneurysmInsert() {
      this.$refs.aneurysmInfoDialog.Show()
    },
    aneurysmModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条动脉瘤信息')
      if (this.selectedInfo) {
        this.$refs.aneurysmInfoDialog.Show(this.selectedInfo)
      }
    },
    aneurysmDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条动脉瘤信息')
      this.$confirm('是否删除该动脉瘤信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedInfo.id) {
            const index = this.aneurysmTableData.findIndex(
              (item) => item.id === this.selectedInfo.id
            )
            if (index !== -1) {
              this.aneurysmTableData.splice(index, 1)
            }
          } else {
            const index = this.aneurysmTableData.findIndex(
              (item) => item.ids === this.selectedInfo.ids
            )
            if (index !== -1) {
              this.aneurysmTableData.splice(index, 1)
            }
          }
          this.selectedInfo = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    getAllSuggestions(val, type) {
      if (val.handlingOpinions?.includes('造影')) {
        this._getNewRadiography(val, type)
      }
      if (val.handlingOpinions?.includes('支架植入')) {
        this._getNewImplantation(val, type)
      }
    },
    // 造影
    async _getNewRadiography(val, type) {
      if (type === 'init') {
        this.radiographyDetail = {
          ...this.$options.data().radiographyDetail
        }
        val.aorticAneurysmRadiography = this.radiographyDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmRadiography')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticAneurysmRadiography = res.data.data[0]
                this.radiographyDetail = val.aorticAneurysmRadiography
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticAneurysmRadiography) {
          this.radiographyDetail = val.aorticAneurysmRadiography
        } else {
          this.radiographyDetail = {
            ...this.$options.data().radiographyDetail
          }
          val.aorticAneurysmRadiography = this.radiographyDetail
        }
      }

      // 造影表格 主动脉瘤支架植入基本信息
      if (type === 'init') {
        this.radiographyLeftTableDetail = []
        this.radiographyRightTableDetail = []
        val.radiographyLeftTableDetail = this.radiographyLeftTableDetail
        val.radiographyRightTableDetail = this.radiographyRightTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmIliacStent')
        this.$api.post(url, formData).then(
          (res) => {
            console.log('res:', res)
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                const leftTable =
                  res.data.data.filter((item) => item.supportPosition?.includes('左髂支')) || []
                const rightTable =
                  res.data.data.filter((item) => item.supportPosition?.includes('右髂支')) || []
                val.radiographyLeftTableDetail = leftTable
                val.radiographyRightTableDetail = rightTable
                this.radiographyLeftTableDetail = val.radiographyLeftTableDetail
                this.radiographyRightTableDetail = val.radiographyRightTableDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.radiographyLeftTableDetail || val.radiographyRightTableDetail) {
          this.radiographyLeftTableDetail = val.radiographyLeftTableDetail
          this.radiographyRightTableDetail = val.radiographyRightTableDetail
        } else {
          this.radiographyLeftTableDetail = this.$options.data().radiographyLeftTableDetail
          this.radiographyRightTableDetail = this.$options.data().radiographyRightTableDetail
          val.radiographyLeftTableDetail = this.radiographyLeftTableDetail
          val.radiographyRightTableDetail = this.radiographyRightTableDetail
        }
      }
    },
    // 支架(基本信息)
    async _getNewImplantation(val, type) {
      if (type === 'init') {
        this.implantationDetail = {
          ...this.$options.data().implantationDetail
        }
        val.aorticAneurysmImplantation = this.implantationDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmImplantation')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticAneurysmImplantation = res.data.data[0]
                this.implantationDetail = val.aorticAneurysmImplantation
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticAneurysmImplantation) {
          this.implantationDetail = val.aorticAneurysmImplantation
        } else {
          this.implantationDetail = {
            ...this.$options.data().implantationDetail
          }
          val.aorticAneurysmImplantation = this.implantationDetail
        }
      }

      // 肾动脉
      if (type === 'init') {
        this.renalArteryInfo = {
          ...this.$options.data().renalArteryInfo
        }
        val.aorticAneurysmRenalArtery = this.renalArteryInfo
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmRenalArtery')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticAneurysmRenalArtery = res.data.data[0]
                this.renalArteryInfo = val.aorticAneurysmRenalArtery
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticAneurysmRenalArtery) {
          this.renalArteryInfo = val.aorticAneurysmRenalArtery
        } else {
          this.renalArteryInfo = {
            ...this.$options.data().renalArteryInfo
          }
          val.aorticAneurysmRenalArtery = this.renalArteryInfo
        }
      }
      // 左
      if (type === 'init') {
        this.leftCoverTableDetail = []
        val.aorticAneurysmLeftIliacBranch = this.leftCoverTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmLeftIliacBranch')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticAneurysmLeftIliacBranch = res.data.data
                this.leftCoverTableDetail = val.aorticAneurysmLeftIliacBranch
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticAneurysmLeftIliacBranch) {
          this.leftCoverTableDetail = val.aorticAneurysmLeftIliacBranch
        } else {
          this.leftCoverTableDetail = this.$options.data().leftCoverTableDetail
          val.aorticAneurysmLeftIliacBranch = this.leftCoverTableDetail
        }
      }
      // 右
      if (type === 'init') {
        this.rightReconstructionDetail = []
        val.aorticAneurysmRightIliacBranch = this.rightReconstructionDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticAneurysmRightIliacBranch')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticAneurysmRightIliacBranch = res.data.data
                this.rightReconstructionDetail = val.aorticAneurysmRightIliacBranch
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticAneurysmRightIliacBranch) {
          this.rightReconstructionDetail = val.aorticAneurysmRightIliacBranch
        } else {
          this.rightReconstructionDetail = this.$options.data().rightReconstructionDetail
          val.aorticAneurysmRightIliacBranch = this.rightReconstructionDetail
        }
      }
    },

    _rowClick(row) {
      // if (this.isReadonly) return
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _rowClickOther(row) {
      // if (this.isReadonly) return
      this.selectedRow = null
      this.selectedRow = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.aneurysmInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.aneurysmTableData.length; i++) {
          const element = this.aneurysmTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.aneurysmTableData[i] = val
            this.$set(this.aneurysmTableData, i, this.aneurysmTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.aneurysmTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.aneurysmTableData.length; i++) {
          const element = this.aneurysmTableData[i]
          if (val.id === element.id) {
            this.aneurysmTableData[i] = val
            this.$set(this.aneurysmTableData, i, this.aneurysmTableData[i])
            break
          }
        }
      }
    },
    /*------------------------------------------------------------*/
    // 左（增）
    addLeftRadiography() {
      if (this.isReadonly) return
      this.addRadiographyType = 'left'
      this.$refs.RadiographyNewDialog.Show()
    },
    // 左（删）
    radiographyLeftDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyLeftTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyLeftTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyLeftTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyLeftTableDetail.splice(index, 1)
          }
        }
      }
    },
    // 右（增）
    addRightRadiography() {
      if (this.isReadonly) return
      this.addRadiographyType = 'right'
      this.$refs.RadiographyNewDialog.Show()
    },
    // 右（删）
    radiographyRightDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyRightTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyRightTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyRightTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyRightTableDetail.splice(index, 1)
          }
        }
      }
    },
    radiography_new_dialogSave(val) {
      if (this.addRadiographyType == 'left') {
        val.supportPosition = '左髂支'
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.radiographyLeftTableDetail.length; i++) {
            const element = this.radiographyLeftTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.radiographyLeftTableDetail[i] = val
              this.$set(this.radiographyLeftTableDetail, i, this.radiographyLeftTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.radiographyLeftTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.radiographyLeftTableDetail.length; i++) {
            const element = this.radiographyLeftTableDetail[i]
            if (val.id === element.id) {
              this.radiographyLeftTableDetail[i] = val
              this.$set(this.radiographyLeftTableDetail, i, this.radiographyLeftTableDetail[i])
              break
            }
          }
        }
      } else if (this.addRadiographyType == 'right') {
        val.supportPosition = '右髂支'
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.radiographyRightTableDetail.length; i++) {
            const element = this.radiographyRightTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.radiographyRightTableDetail[i] = val
              this.$set(this.radiographyRightTableDetail, i, this.radiographyRightTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.radiographyRightTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.radiographyRightTableDetail.length; i++) {
            const element = this.radiographyRightTableDetail[i]
            if (val.id === element.id) {
              this.radiographyRightTableDetail[i] = val
              this.$set(this.radiographyRightTableDetail, i, this.radiographyRightTableDetail[i])
              break
            }
          }
        }
      }
    },
    /* ------------------------  支架---------------------------------- */
    /*     肾动脉         */
    renalArteryInsert() {
      if (this.isReadonly) return

      this.$refs.renalArteryDialog.Show()
    },
    // 肾动脉表格删除
    renalArteryDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.renalArteryTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.renalArteryTableDetail.splice(index, 1)
          }
        } else {
          const index = this.renalArteryTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.renalArteryTableDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = {}
      //刷新表格
      this.tableKey = Math.random()
    },
    // 肾动脉 保存
    renalArtery_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.renalArteryTableDetail.length; i++) {
          const element = this.renalArteryTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.renalArteryTableDetail[i] = val
            this.$set(this.renalArteryTableDetail, i, this.renalArteryTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.renalArteryTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.renalArteryTableDetail.length; i++) {
          const element = this.renalArteryTableDetail[i]
          if (val.id === element.id) {
            this.renalArteryTableDetail[i] = val
            this.$set(this.renalArteryTableDetail, i, this.renalArteryTableDetail[i])
            break
          }
        }
      }
    },
    /*         左髂支       */
    leftCoverInsert() {
      if (this.isReadonly) return

      this.$refs.leftCoverDialog.Show()
    },
    // 左髂内动脉 删除
    leftCoverDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.leftCoverTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.leftCoverTableDetail.splice(index, 1)
          }
        } else {
          const index = this.leftCoverTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.leftCoverTableDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = null
      //刷新表格
      this.tableKey = Math.random()
    },
    // 左髂内动脉 保存
    leftCover_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.leftCoverTableDetail.length; i++) {
          const element = this.leftCoverTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.leftCoverTableDetail[i] = val
            this.$set(this.leftCoverTableDetail, i, this.leftCoverTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.leftCoverTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.leftCoverTableDetail.length; i++) {
          const element = this.leftCoverTableDetail[i]
          if (val.id === element.id) {
            this.leftCoverTableDetail[i] = val
            this.$set(this.leftCoverTableDetail, i, this.leftCoverTableDetail[i])
            break
          }
        }
      }
    },
    /*         右髂支           */
    rightReconstructionInsert() {
      if (this.isReadonly) return

      this.$refs.rightReconstructionDialog.Show()
    },
    // 右髂内动脉 删除
    rightReconstructionDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.rightReconstructionDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.rightReconstructionDetail.splice(index, 1)
          }
        } else {
          const index = this.rightReconstructionDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.rightReconstructionDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = null
      //刷新表格
      this.tableKey = Math.random()
    },
    // 右髂内动脉 保存
    rightReconstruction_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.rightReconstructionDetail.length; i++) {
          const element = this.rightReconstructionDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.rightReconstructionDetail[i] = val
            this.$set(this.rightReconstructionDetail, i, this.rightReconstructionDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.rightReconstructionDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.rightReconstructionDetail.length; i++) {
          const element = this.rightReconstructionDetail[i]
          if (val.id === element.id) {
            this.rightReconstructionDetail[i] = val
            this.$set(this.rightReconstructionDetail, i, this.rightReconstructionDetail[i])
            break
          }
        }
      }
    },
    // 是否重建
    changeRenalArtery(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.renalArtery_status = true
      } else {
        this.renalArtery_status = false
      }
    },
    changeLeftReconstruction(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.leftReconstruction_status = false
      } else {
        this.leftReconstruction_status = true
      }
    },
    changeRightReconstruction(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.rightReconstruction_status = false
      } else {
        this.rightReconstruction_status = true
      }
    },
    // 内漏
    changeComplication(val) {
      if (val && val.includes('内漏')) {
        this.showNLType = true
      } else {
        this.showNLType = false
        this.radiographyDetail.radiographyInternalLeakage = null
      }
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.aneurysm-info-entry {
  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 40%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
  .info-table {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px 15px;
    border-radius: 5px;
    position: relative;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    /deep/.el-table .cell {
      white-space: normal;
    }
    /deep/.top-item-row .item-row span {
      margin: 0 0 0 5px;
    }
    button {
      margin-right: 0.5rem;
    }
  }
  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .equipment_style {
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 45%;
        margin: 0.4rem 1rem;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }
  .radiography-table-left {
    padding: 10px;
  }
}
</style>
