import { render, staticRenderFns } from "./LeftCoverDialog.vue?vue&type=template&id=4579dd7a&scoped=true"
import script from "./LeftCoverDialog.vue?vue&type=script&lang=js"
export * from "./LeftCoverDialog.vue?vue&type=script&lang=js"
import style0 from "./LeftCoverDialog.vue?vue&type=style&index=0&id=4579dd7a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4579dd7a",
  null
  
)

export default component.exports