<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 35%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    title="左髂内动脉重建支架详情"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>支架直径（mm）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="leftCoverInfo.supportDiameter"
          size="mini"
        ></el-input>
      </div>
      <div class="item-row">
        <span>支架长度（mm）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="leftCoverInfo.supportLength"
          size="mini"
        ></el-input>
      </div>
      <div class="item-row">
        <span>支架数量（枚）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="leftCoverInfo.supportNumber"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'LeftCoverDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      leftCoverInfo: {
        supportDiameter: null, //支架直径
        supportLength: null, //支架长度
        supportNumber: null //支架数量
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    Show(bloodVessel) {
      this.leftCoverInfo = {}
      if (bloodVessel) this.leftCoverInfo = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      this.$emit('save', this.leftCoverInfo)
      this.isShow = false
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
